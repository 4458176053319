/**
 * This helper template literal is only used for autocompletion in Tailwind CSS.
 * It is not used for actual styling.
 */
export const tw = (
  strings: TemplateStringsArray,
  ...values: unknown[]
): string => {
  return strings.reduce((acc, str, i) => acc + str + (values[i] || ""), "");
};
